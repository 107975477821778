var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"041d27f7033e4c4d9ea0657dfa94a7deb53768fc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { isLocalEnvironment, sentryDns } from './src/env';

if (!isLocalEnvironment) {
  Sentry.init({
    dsn: sentryDns,
    tracesSampleRate: 0.2,
    integrations: [new BrowserTracing()],
  });
}
